/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Boostrap variables
// Import Bootstrap source files from node_modules
@import 'bootswatch/dist/united/variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-vue/src/index.scss';
@import 'bootswatch/dist/united/bootswatch';
/* Dirty fix on navbar active link with bootswatch theme */
nav li.router-link-active .navbar-dropdown-menu {
  color: $navbar-light-active-color;
}

/* jhipster-needle-scss-add-vendor  Enhouse billing will add new css style */

.btn-primary {
  background-color: #28a745;
  border-color: #28a745;
  &:hover {
    background-color: #1f8337;
    border-color: #1f8337;
  }
}
a {
  color: #28a745;
  &:hover {
    color: #1f8337;
  }
}
.page-item.active .page-link {
  background-color: #28a745;
  border-color: #28a745;
}

.sort {
  cursor: pointer;
}
